import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { TabsTopDivisionScores } from "./TabsTopDivisionScores";
import useWindowDimensions from "./WindowDimensions";

export const TabsTopEventsScores = (props) => {
  const [currentTab, setCurrentTab] = useState("36");

  const { width } = useWindowDimensions();

  const tabs = [
    {
      id: 31,
      tabTitle: "Atlanta",
      tabDate: "5/9 - 5/12",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP1"}
            tabTitle={"MLP 1"}
            tabSite={"Atlanta, GA"}
            tabDate={"May 9th - 12th"}
            season={"2024"}
            timezone={"ET"}
          />
        </>
      ),
    },
    {
      id: 32,
      tabTitle: "Wash D.C.",
      tabDate: "6/13 - 6/16",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP2"}
            tabTitle={"MLP 2"}
            tabSite={"Washington D.C."}
            tabDate={"June 13th - 16th"}
            season={"2024"}
            timezone={"ET"}
          />
        </>
      ),
    },
    {
      id: 33,
      tabTitle: "ATR MLP MST",
      tabDate: "7/10 - 7/14",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP3"}
            tabTitle={"MLP 3"}
            tabSite={"Grand Rapids"}
            tabDate={"July 10th - 14th"}
            season={"2024"}
            timezone={"ET"}
          />
        </>
      ),
    },
    {
      id: 34,
      tabTitle: "Salt Lake",
      tabDate: "7/25 - 7/28",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP4"}
            tabTitle={"MLP 4"}
            tabSite={"Salt Lake City, UT"}
            tabDate={"July 25th - 28th"}
            season={"2024"}
            timezone={"MT"}
          />
        </>
      ),
    },
    {
      id: 35,
      tabTitle: "Kansas City",
      tabDate: "8/3 - 8/5",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP5"}
            tabTitle={"MLP 5"}
            tabSite={"Kansas City, MO"}
            tabDate={"Aug 3rd - 5th"}
            season={"2024"}
            timezone={"CT"}
          />
        </>
      ),
    },
    {
      id: 36,
      tabTitle: "New York",
      tabDate: "9/19 - 9/22",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP6"}
            tabTitle={"MLP 6"}
            tabSite={"New York, NY"}
            tabDate={"Sep 19th - 22nd"}
            season={"2024"}
            timezone={"ET"}
          />
        </>
      ),
    },
    {
      id: 37,
      tabTitle: "Virginia Beach",
      tabDate: "9/26 - 9/29",
      content: (
        <>
          <TabsTopDivisionScores
            location={"2024 MLP7"}
            tabTitle={"MLP 7"}
            tabSite={"Virginia Beach, VA"}
            tabDate={"Sep 26th - 29th"}
            season={"2024"}
            timezone={"ET"}
          />
        </>
      ),
    },
    // {
    //   id: 38,
    //   tabTitle: "Las Vegas",
    //   tabDate: "10/14 - 10/16",
    //   content: (
    //     <>
    //       <TabsTopDivisionScores
    //         location={"2024 MLP8"}
    //         tabTitle={"MLP 8"}
    //         tabSite={"Las Vegas, NV"}
    //         tabDate={"Oct 14th - 16th"}
    //         season={"2024"}
    //         timezone={"PT"}
    //       />
    //     </>
    //   ),
    // },
    // {
    //   id: 39,
    //   tabTitle: "Miami",
    //   tabDate: "10/24 - 10/27",
    //   content: (
    //     <>
    //       <TabsTopDivisionScores
    //         location={"2024 MLP9"}
    //         tabTitle={"MLP 9"}
    //         tabSite={"Miami, FL"}
    //         tabDate={"Oct 24th - 27th"}
    //         season={"2024"}
    //         timezone={"ET"}
    //       />
    //     </>
    //   ),
    // },
  ];
  const handleTabClick = (e) => {
    // console.log("firing");
    // console.log(e.target.id);
    setCurrentTab(e.target.id);
  };

  return (
    <>
      <style type="text/css">
        {`
    .divisionBtn{
      background-color: white;
      border: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #ef612d;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .divisionBtn:hover{
      background-color: white;
      border: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #ef612d;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .divisionBtn:focus{
      background-color: #white;
      border: none;
      color: #ef612d;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    `}{" "}
      </style>
      <div
        style={{
          maxWidth: "1200px",
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "#0a2137", //HERE WHITE
          marginTop: "-16px",
          paddingLeft: "10px",
        }}
        className="container"
      >
        {width < 90000 ? (
          <div
            style={{
              backgroundColor: "#0a2137",
              overflowX: "scroll",
              width: "100%",
            }}
            className="tabs d-flex"
          >
            {tabs.map((tab, i) => (
              <Button
                type="button"
                style={{
                  marginTop: "2px",
                  marginLeft: "2px",
                  marginRight: "2px",
                  marginBottom: ".5px",
                  fontSize: "11px",
                  lineHeight: "15px",
                  fontWeight: "400",
                  height: "40px",
                  margin: "5px",
                  borderRadius: "0px",
                  borderColor: "white",
                  borderWidth: "0px",
                  borderStyle: "solid",
                  transform: "skewX(-20deg)",
                  fontFamily: "SofiaCondensed",
                }}
                key={i}
                className={"btn btn-lg divisionBtn font-sofia"}
                active={currentTab !== `${tab.id}`}
                variant="danger"
                id={tab.id}
                size="sm"
              >
                <div
                  id={tab.id}
                  onClick={handleTabClick}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    width: "100px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    id={tab.id}
                    onClick={handleTabClick}
                  >
                    {tab.tabTitle}
                  </Row>
                  <Row
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",

                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    id={tab.id}
                    onClick={handleTabClick}
                  >
                    {tab.tabDate}
                  </Row>
                </div>
                <div
                  id={tab.id}
                  onClick={handleTabClick}
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                ></div>
              </Button>
            ))}{" "}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#0a2137",
            }}
            className="tabs d-flex justify-content-around"
          >
            {/* {tabs.map((tab, i) => (
              <Button
                type="button"
                style={{
                  marginTop: "2px",
                  marginLeft: "2px",
                  marginRight: "2px",
                  marginBottom: ".5px",
                  fontSize: "11px",
                  lineHeight: "15px",
                  fontWeight: "400",
                  height: "30px",
                  margin: "10px",
                  width: "40%",
                  borderRadius: "0px",
                  borderColor: "white",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  transform: "skewX(-20deg)",
                  fontFamily: "SofiaCondensed",
                }}
                key={i}
                className={"btn btn-lg divisionBtn font-sofia"}
                active={currentTab !== `${tab.id}`}
                variant="danger"
                id={tab.id}
                size="sm"
              >
                <div
                  id={tab.id}
                  onClick={handleTabClick}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  <a id={tab.id} onClick={handleTabClick}>
                    {tab.tabTitle}
                  </a>
                </div>
                <div
                  id={tab.id}
                  onClick={handleTabClick}
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                ></div>
              </Button>
            ))}{" "} */}
          </div>
        )}
        <div className="content">
          {tabs.map((tab, i) => (
            <>
              <div key={i}>
                {currentTab === `${tab.id}` && (
                  <div>
                    <p className="title">{tab.title}</p>
                    <div>{tab.content}</div>
                  </div>
                )}{" "}
              </div>
            </>
          ))}{" "}
        </div>
      </div>
    </>
  );
};
