import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { TbScoreboard } from "react-icons/tb";
import { FaListUl } from "react-icons/fa6";
import { BsCameraReelsFill } from "react-icons/bs";

// import LogoImage from "./Capture2.svg";
import AtlantaLogo from "./images/playoffs_event_logo.png";
import LogoImage from "./images/logo.png";
import background from "./images/playoffs_app_top2.png";
import background2 from "./images/playoffs_app_top.png";
import background3 from "./images/landingMatches.png";
import background4 from "./images/landingMatches2.png";

import { TabsDivision } from "./TabsDivision";
import { TabsTopEventsScores } from "./TabsTopEventsScores";
import { TabsTopEventsStandings } from "./TabsTopEventsStandings";
import useWindowDimensions from "./WindowDimensions";

export const TabsTop = (props) => {
  var sectionStyle = {
    backgroundImage: `url(${LogoImage})`,
  };
  const { height, width } = useWindowDimensions();
  const mobileScreen = width < 900 ? background : background2;
  const mobileScreen2 = width < 900 ? background3 : background4;

  const queryParameters = new URLSearchParams(window.location.search)
  const URLtype = queryParameters.get("page")
  const URLDiv = queryParameters.get("division")
  let URLlist = ''

  const [currentTab, setCurrentTab] = useState("11");

  const onRender =()=>{
    if (currentTab==="11" && URLtype==='standings' ){
      URLlist='teams'
    setCurrentTab("12")
    queryParameters.delete("page")
    }
    if (currentTab==="11" && URLtype==='standingsP' ){
      URLlist='teams'
    setCurrentTab("12")
    queryParameters.delete("page")
    }
    if (currentTab==="11" && URLtype==='standingsC' ){
      URLlist='teams'
    setCurrentTab("12")
    queryParameters.delete("page")
    }
    if (currentTab==="11" && URLtype==='playersP' ){
      URLlist='players'
    setCurrentTab("12")
    queryParameters.delete("page")
    }
    if (currentTab==="11" && URLtype==='playersC' ){
      URLlist='players'
    setCurrentTab("12")
    queryParameters.delete("page")
    }
  }

  onRender();

  const tabs = [
    {
      id: 11,
      tabTitle: "SCORES",
      tabIcon: <TbScoreboard id='11' size="2em" />,
      content: (
        <>
          <TabsTopEventsScores />
        </>
      ),
    },
    // {
    //   id: 12,
    //   tabTitle: "LEAGUE TEAM STANDINGS",
    //   tabIcon: <FaListUl id='12' size="1em" />,
    //   content: (
    //     <>
    //       <TabsTopEventsStandings urlDiv={URLDiv} />
    //     </>
    //   ),
    // },
    {
      id: 12,
      tabTitle: "LEAGUE STANDINGS",
      tabIcon: <FaListUl id='12' size="1em" />,
      content: (
        <>
          <TabsTopEventsStandings urlDiv={URLDiv} URLlist={URLlist} />
        </>
      ),
    },
    {
      id: 13,
      tabTitle: "WATCH MATCHES",
      tabIcon: <BsCameraReelsFill id='14' size="1em" />,
      content: (
        <>
          Loading Matches...
        </>
      ),
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);

    if (e.target.id === "11") {
      window.location.href =
        "https://scores.majorleaguepickleball.net";
    }
    if (e.target.id === "13") {
      window.location.href =
        "https://pickleballtv.com/";
        // "https://www.youtube.com/live/P4AMxBKXB8Y"

    }
    
    setCurrentTab(e.target.id);
  };

  return (
    <>
      <style type="text/css">
        {`
    .TOPsBtn{
      background-color: #121f34;
      border: none;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      color: white;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .TOPsBtn:hover{
      background-color: #121f34;
      border: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #white;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .TOPsBtn:focus{
      background-color: #121f34;
      border: none;
      color: white;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    `}{" "}
      </style>
      <div
        style={{
          maxWidth: "1200px",
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "0a2137",
          padding: "0px",
        }}
        className="container"
      >
        <div
          style={{
            backgroundColor: "#ef612dff",
            position: "-webkit-sticky",
            position: "sticky",
            top: 60,
            zIndex: 9998,
            margin: "auto",
            display: "flex",
            alignItems: "center",
          }}
          className="tabs justify-content-around"
        >
          {tabs.map((tab, i) => (
            <Button
              type="button"
              style={{
                marginTop: "2px",
                marginLeft: "2px",
                marginRight: "2px",
                marginBottom: ".5px",
                fontSize: "11px",
                lineHeight: "14px",
                fontWeight: "600",
                zIndex: 1000,
                height: "30px",
                margin: "10px",
                width: "30%",
                borderRadius: "0px",
                transform: "skewX(-20deg)",
                // borderTopLeftRadius: "5px",
                // borderTopRightRadius: "5px",
                // borderBottomLeftRadius: "5px",
                // borderBottomRightRadius: "5px",
                fontFamily: "SofiaCondensed",
              }}
              key={i}
              className={"btn btn-lg TOPsBtn font-sofia"}
              id={tab.id}
              active={currentTab !== `${tab.id}`}
              onClick={handleTabClick}
              variant="info"
              size="sm"
            >
              <div
                id={tab.id}
                onClick={handleTabClick}
                style={{ transform: "skewX(20deg)" }}
              >
                <a id={tab.id} onClick={handleTabClick}>
                  {tab.tabIcon}
                </a>
                <a id={tab.id} onClick={handleTabClick}>
                  &nbsp;&nbsp;{tab.tabTitle}
                </a>
              </div>
            </Button>
          ))}{" "}
        </div>
        {currentTab === "11" ? (
          <>
            <div
              align="center"
              style={{
                paddingBottom: "5px",
                backgroundColor: "#0a2137", //HERE WHITE

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // background: "center",
                // backgroundImage: `url(${background})`
                // backgroundImage:
                //   // "linear-gradient(to bottom, transparent 0%, white 95%)," +
                //   currentTab=="1"?`url(${mobileScreen})`:`url(${mobileScreen2})`,
                }}
            >
              <div
                style={{
                  // maxWidth: "1700px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    textAlign: "center",
                  }}
                >
                  <div
                    align="center"
                    style={{
                      fontSize: "calc(1.2em + 1.2vw)",
                      color: "WHITE",
                      fontFamily: "kanit",
                    }}
                  >
                    EVENT MATCHES & STANDINGS
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              align="center"
              style={{
                paddingBottom: "5px",

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage:
                  // "linear-gradient(to bottom, transparent 0%, white 95%)," +
                  `url(${mobileScreen})`,
              }}
            >
              <div
                style={{
                  // maxWidth: "1700px",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  <div
                    align="center"
                    style={{
                      fontSize: "calc(1.2em + 1.2vw)",
                      color: "WHITE",
                      fontFamily: "kanit",
                    }}
                  >
                    LEAGUE STANDINGS
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="content">
          {tabs.map((tab, i) =>
            i === 2 ? (
              <div key={i}>
                {currentTab === `${tab.id}` && (
                  <div>
                    <p className="title">{tab.title}</p>
                    <div>{tab.content}</div>
                  </div>
                )}{" "}
              </div>
            ) : (
              <div key={i}>
                {currentTab === `${tab.id}` && (
                  <div>
                    <p className="title">{tab.title}</p>
                    <div>{tab.content}</div>
                  </div>
                )}{" "}
              </div>
            )
          )}{" "}
        </div>
      </div>
    </>
  );
};
