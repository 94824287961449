import { FaRegDotCircle } from "react-icons/fa";

export const Live = (props) => {
  return (
    <p>
      <FaRegDotCircle class="record" />
      &nbsp; live
    </p>
  );
};
