import React, { useState } from "react";
import { StatsTeamBen } from "./StatsTeamBen";
import { StatsPlayerBen } from "./StatsPlayerBen";

import Button from "react-bootstrap/Button";
import { FaUsers } from "react-icons/fa6";
import { FaUsersRectangle } from "react-icons/fa6";
import { FaCircleUser } from "react-icons/fa6";

export const TabsTopAnalytics = (props) => {
  const { location, round, division, URLlist, urlDiv } = props;
  const [currentTab, setCurrentTab] = useState("1");
  console.log('TopAnalytics');

  const queryParameters = new URLSearchParams(window.location.search)

  const URLtype = queryParameters.get("page")

  const onRender = () => {
    if (currentTab === "1" && URLtype === 'playersP') {
      //   URLlist='teams'
      setCurrentTab("2")
      queryParameters.delete("page")
    }
    if (currentTab === "1" && URLtype === 'playersC') {
      //   URLlist='teams'
      setCurrentTab("2")
      queryParameters.delete("page")
    }
    if (currentTab === "2" && URLtype === 'standingsC') {
      //   URLlist='teams'
      setCurrentTab("1")
      queryParameters.delete("page")
    }
    if (currentTab === "2" && URLtype === 'standingsP') {
      //   URLlist='teams'
      setCurrentTab("1")
      queryParameters.delete("page")
    }
  }

  onRender();

  const tabs = [
    {
      id: 1,
      tabTitle: "TEAM STANDINGS",
      tabIcon: <FaUsersRectangle id='12' size="2em" />,
      content: (
        <>
          <StatsTeamBen location={location}
            division={division}
            round={round} />
        </>
      ),
    },
    {
      id: 2,
      tabTitle: "PLAYER STANDINGS",
      tabIcon: <FaCircleUser id='12' size="2em" />,

      content: (
        <>
          <StatsPlayerBen location={location}
            division={division}
            round={round} />
        </>
      ),
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);

    if (queryParameters.get("page")==='playersC') {
      window.location.href =
        "/?page=standings";
    }
    if (queryParameters.get("page")==='playersP') {
      window.location.href =
        "/?page=standings";
    }
    if (queryParameters.get("page")==='standingsC') {
      window.location.href =
        "/?page=standings";
    }
    if (queryParameters.get("page")==='standingsP') {
      window.location.href =
        "/?page=standings";
    }
  };



  return (
    <>
      <style type="text/css">
        {`
    .analyticsBtn{
      background-color: #0a2137;
      border: none;
      color: white;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .analyticsBtn:hover{
      background-color: #0a2137;
      border: none;
      color: white;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .analyticsBtn:focus{
      background-color: #0a2137;
      border: none;
      color: white;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    `}{" "}
      </style>
      <div>
        <div
          style={{
            maxWidth: "1200px",
            alignContent: "center",
            justifyContent: "center",
            backgroundColor: "#0a2137",
            marginTop: "-25px",
            color: "#ef612d",
          }}
        >
          <div
            style={
              {
                // backgroundColor: "#0a2137",
              }
            }
            className=" d-flex justify-content-around"
          >

            {tabs.map((tab, i) => (
              <Button
                className="analyticsBtn"
                style={{
                  width: "50vw",
                  height: "40px",
                  marginTop: "5px",
                  marginBottom: "-5px",
                  margin: "1px",
                  fontSize: "12px",
                  fontWeight: "600",
                  borderRadius: "0px",
                  color: "white",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  fontFamily: "SofiaCondensed",
                }}
                key={i}
                id={tab.id}
                active={currentTab !== `${tab.id}`}
                onClick={handleTabClick}
                variant="primary"
                size="sm"
              >
                <a id={tab.id} onClick={handleTabClick}>
                  {tab.tabIcon}
                </a>
                <a id={tab.id} onClick={handleTabClick}>
                  &nbsp;&nbsp;{tab.tabTitle}
                </a>
              </Button>
            ))}
          </div>
          <div
            style={{
              marginTop: "-17px",
              backgroundColor: "#0a2137",
            }}
            className="content container"
          >
            {tabs.map((tab, i) => (
              <div
                style={{ backgroundColor: "#0a2137", paddingTop: "10px" }}
                key={i}
              >
                {currentTab === `${tab.id}` && (
                  <div>
                    <p className="title">{tab.title}</p>
                    <div>{tab.content}</div>
                  </div>
                )}{" "}
              </div>
            ))}{" "}
          </div>
        </div>
      </div>
    </>
  );
};
