import React, { useState } from "react";
import Button from "react-bootstrap/Button";

import { TabsAnalytics } from "./TabsAnalytics";
import logoATL from "./images/atlantaHeader.png";
import logoDC from "./images/dcHeader.png";
import logoMid from "./images/logoMid.png";
import logoSLC from "./images/slcHeader.png";
import logoKC from "./images/kcHeader.png";
import logoNY from "./images/nyHeader.png";
import logoVB from "./images/vbHeader.png";


import { FaPlayCircle } from "react-icons/fa";

import background4 from "./images/landingMatches.png";

export const TabsTopDivisionScores = (props) => {
  const { location, season, tabSite, tabDate, tabTitle, timezone } = props;
  const [currentTab, setCurrentTab] = useState("2");

  const tabs1 = [
    {
      id: 2,
      tabTitle: "CHALLENGER",
      content: (
        <>
          <TabsAnalytics
            location={location}
            season={season}
            day={""}
            event={"Challenger"}
            site={tabSite}
            timezone={timezone}
          />
        </>
      ),
    },
  ];


  const tabs2 = [
    {
      id: 2,
      tabTitle: "PREMIER",
      content: (
        <>
          <TabsAnalytics
            location={location}
            season={season}
            day={""}
            event={"Premier"}
            site={tabSite}
            timezone={timezone}
          />
        </>
      ),
    },
    {
      id: 3,
      tabTitle: "CHALLENGER",
      content: (
        <>
          <TabsAnalytics
            location={location}
            season={season}
            day={""}
            event={"Challenger"}
            site={tabSite}
            timezone={timezone}
          />
        </>
      ),
    },
  ];

  const tabs= tabSite==='Virginia Beach, VA' ? tabs1 : tabs2
  const handleTabClick = (e) => {
    // console.log("firing");
    // console.log(e.target.id);
    setCurrentTab(e.target.id);
    if (e.target.id === "1") {
      document.getElementById("1").style.backgroundColor = "white";
      document.getElementById("2").style.backgroundColor = "#44566e";
      document.getElementById("3").style.backgroundColor = "#44566e";
    }
    if (e.target.id === "2") {
      document.getElementById("2").style.backgroundColor = "white";
      document.getElementById("1").style.backgroundColor = "#44566e";
      document.getElementById("3").style.backgroundColor = "#44566e";
    }
    if (e.target.id === "3") {
      document.getElementById("3").style.backgroundColor = "white";
      document.getElementById("1").style.backgroundColor = "#44566e";
      document.getElementById("2").style.backgroundColor = "#44566e";
    }
  };

  return (
    <>
      <style type="text/css">
        {`
    .divisionBtn{
      background-color: white;
      border: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #ef612d;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .divisionBtn:hover{
      background-color: white;
      border: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #ef612d;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .divisionBtn:focus{
      background-color: white;
      border: none;
      color: #ef612d;
      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    `}{" "}
      </style>

      <div
        style={{
          maxWidth: "1200px",
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "#0a2137", //HERE WHITE
          padding: "0px",
        }}
        className="container"
      >
        {tabSite === "Atlanta, GA" ? (
          <>
            <div
              align="center"
              style={{
                height: "120px",
                marginTop: "-15px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img width="400" alt="mlp logo" src={logoATL} />
              </div>
            </div>
          </>
        ) : tabSite === "Washington D.C." ? (
          <>
            <div
              align="center"
              style={{
                height: "120px",
                marginTop: "-15px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img
                  style={{ paddingTop: 7 }}
                  width="350"
                  alt="mlp logo"
                  src={logoDC}
                />
              </div>
            </div>
          </>
        ) : tabSite === "Grand Rapids" ? (
          <>
            <Button
              onClick={() => {
                window.location.href =
                  // "https://www.youtube.com/live/P4AMxBKXB8Y"
                  "https://www.majorleaguepickleball.net/watch/";
              }}
              style={{
                width: "100%",
                marginTop: "-15px",
                borderRadius: "0px",
                backgroundColor: "#364557",
                color: "white",
                fontFamily: "Kanit",
              }}
              variant="secondary"
              size="lg"
            >
              <FaPlayCircle />
              &nbsp; WATCH MATCHES
            </Button>
            <div
              align="center"
              style={{
                height: "120px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img
                  style={{ paddingTop: 7 }}
                  width="350"
                  alt="mlp logo"
                  src={logoMid}
                />
              </div>
            </div>
          </>
        ) : tabSite === "Salt Lake City, UT" ? (
          <>
            <div
              align="center"
              style={{
                marginTop: "-15px",

                height: "130px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img
                  style={{ paddingTop: 7 }}
                  width="350"
                  alt="mlp logo"
                  src={logoSLC}
                />
              </div>
            </div>
          </>
        ) : tabSite === "Kansas City, MO" ? (
          <>
            <div
              align="center"
              style={{
                height: "130px",
                marginTop: "-15px",

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img
                  style={{ paddingTop: 7 }}
                  width="350"
                  alt="mlp logo"
                  src={logoKC}
                />
              </div>
            </div>
          </>
        ) 
        : tabSite === "New York, NY" ? (
          <>
            <div
              align="center"
              style={{
                height: "130px",
                marginTop: "-15px",

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img
                  style={{ paddingTop: 7 }}
                  width="350"
                  alt="mlp logo"
                  src={logoNY}
                />
              </div>
            </div>
          </>
        ): tabSite === "Virginia Beach, VA" ? (
          <>
            <div
              align="center"
              style={{
                height: "130px",
                marginTop: "-15px",

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // background: "center",
                // backgroundImage: `url(${background})`
                backgroundImage: `url(${background4})`,
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <img
                  style={{ paddingTop: 7 }}
                  width="300"
                  alt="mlp logo"
                  src={logoVB}
                />
              </div>
            </div>
          </>
        )
        // : tabSite === "Las Vegas, NV" ? (
        //   <>
        //     <div
        //       align="center"
        //       style={{
        //         height: "130px",
        //         marginTop: "-15px",

        //         backgroundSize: "cover",
        //         backgroundRepeat: "no-repeat",
        //         backgroundPosition: "center",
        //         // background: "center",
        //         // backgroundImage: `url(${background})`
        //         backgroundImage: `url(${background4})`,
        //       }}
        //     >
        //       <div
        //         style={{
        //           height: "100px",
        //         }}
        //       >
        //         <img
        //           style={{ paddingTop: 7 }}
        //           width="300"
        //           alt="mlp logo"
        //           src={logoVB}
        //         />
        //       </div>
        //     </div>
        //   </>
        // ): tabSite === "Miami, FL" ? (
        //   <>
        //     <div
        //       align="center"
        //       style={{
        //         height: "130px",
        //         marginTop: "-15px",

        //         backgroundSize: "cover",
        //         backgroundRepeat: "no-repeat",
        //         backgroundPosition: "center",
        //         // background: "center",
        //         // backgroundImage: `url(${background})`
        //         backgroundImage: `url(${background4})`,
        //       }}
        //     >
        //       <div
        //         style={{
        //           height: "100px",
        //         }}
        //       >
        //         <img
        //           style={{ paddingTop: 7 }}
        //           width="300"
        //           alt="mlp logo"
        //           src={logoVB}
        //         />
        //       </div>
        //     </div>
        //   </>
        // )
        : (
          <>
            <div
              align="center"
              style={{
                fontSize: "calc(1em + 1.5vw)",
                color: "white",
                fontFamily: "kanit",
              }}
            >
              {tabTitle}
              {" : "}
              {tabSite}
            </div>
            <div
              align="center"
              style={{
                fontSize: "calc(.75em + 1.5vw)",
                color: "white",
                fontFamily: "kanit",
                fontWeight: "normal",
                paddingBottom: "15px",
              }}
            >
              {tabDate}
            </div>
          </>
        )}

        <div
          style={{ backgroundColor: "#0a2137" }}
          className="tabs d-flex justify-content-around"
        >
          {tabs.map((tab, i) => (
            <Button
              type="button"
              style={{
                width: "50vw",
                marginTop: "2px",
                marginLeft: "2px",
                marginRight: "2px",
                marginBottom: "5px",
                fontSize: "16px",
                fontWeight: "600",
                height: "40px",
                borderRadius: "0px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                fontFamily: "SofiaCondensed",
              }}
              key={i}
              className={"btn btn-lg divisionBtn font-sofia"}
              id={tab.id}
              active={currentTab !== `${tab.id}`}
              onClick={handleTabClick}
              variant="danger"
              size="sm"
            >
              <div
                id={tab.id}
                onClick={handleTabClick}
                // style={{fontFamily: "Kanit"}}
              >
                {tab.tabTitle ? (
                  <img
                    id={tab.id}
                    onClick={handleTabClick}
                    alt=""
                    width="30px"
                    src={
                      tab.tabTitle
                        ? require("./images/" +
                            tab.tabTitle.toLowerCase() +
                            ".png")
                        : null
                    }
                  />
                ) : (
                  ""
                )}
                <a id={tab.id} onClick={handleTabClick}>
                  {tab.tabTitle ? tab.tabTitle : "ALL"}{" "}
                </a>
              </div>
            </Button>
          ))}{" "}
        </div>
        <div className="content">
          {tabs.map((tab, i) => (
            <div key={i}>
              {currentTab === `${tab.id}` && (
                <div>
                  <p className="title">{tab.title}</p>
                  <div>{tab.content}</div>
                </div>
              )}{" "}
            </div>
          ))}{" "}
        </div>
      </div>
    </>
  );
};
