import Form from "react-bootstrap/Form";
export const RoundList = (props) => {
  const { setValue, setFunction } = props;

  return (
    <Form.Group controlId="formBasicSelect">
      <Form.Control
        as="select"
        value={setValue}
        onChange={(e) => setFunction(e.target.value)}
      >
        <option key="blankChoice" hidden>
          Select a Region
        </option>
        <option value="DAY 1">Day 1</option>
        <option value="DAY 2">Day 2</option>
        <option value="DAY 3">Day 3</option>
        <option value="DAY 4">Day 4</option>
        <option value="DAY 5">Day 5</option>

        
        <option value="ROUND OF 16">Round of 16</option>
        <option value="QUARTERFINALS">Quarterfinals</option>
        <option value="SIMIFINALS">Simifinals</option>
        <option value="FINALS">Finals</option>
        <option value="CONSOLATION">Consolation</option>
        <option value="3RD PLACE MATCH">3rd Place Match</option>


        <option value="SEASON FINALS">Season Finals</option>
      </Form.Control>
    </Form.Group>
  );
};
