import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Tabs } from "./Tabs";

export const TabsEvents = (props) => {
  const [currentTab, setCurrentTab] = useState("6");
  const tabs = [
    // {
    //   id: 1,
    //   tabTitle: "2024 MLP1",
    //   content: (
    //     <>
    //       <Tabs event={"2024 MLP1"} />
    //     </>
    //   ),
    // },
    // {
    //   id: 2,
    //   tabTitle: "2024 MLP2",
    //   content: (
    //     <>
    //       <Tabs event={"2024 MLP2"} />
    //     </>
    //   ),
    // },
    // {
    //   id: 3,
    //   tabTitle: "2024 MLP3",
    //   content: (
    //     <>
    //       <Tabs event={"2024 MLP3"} />
    //     </>
    //   ),
    // },
    // {
    //   id: 4,
    //   tabTitle: "2024 MLP4",
    //   content: (
    //     <>
    //       <Tabs event={"2024 MLP4"} />
    //     </>
    //   ),
    // },
    // {
    //   id: 5,
    //   tabTitle: "2024 MLP5",
    //   content: (
    //     <>
    //       <Tabs event={"2024 MLP5"} />
    //     </>
    //   ),
    // },
    {
      id: 6,
      tabTitle: "2024 MLP6",
      content: (
        <>
          <Tabs event={"2024 MLP6"} />
        </>
      ),
    },
    {
      id: 7,
      tabTitle: "2024 MLP7",
      content: (
        <>
          <Tabs event={"2024 MLP7"} />
        </>
      ),
    },
    {
      id: 8,
      tabTitle: "2024 MLP8",
      content: (
        <>
          <Tabs event={"2024 MLP8"} />
        </>
      ),
    },
    {
      id: 9,
      tabTitle: "2024 MLP9",
      content: (
        <>
          <Tabs event={"2024 MLP9"} />
        </>
      ),
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  return (
    <>
      <div  className="container text-center justify-content-around">
        <div style={{ padding: "10px", overflowX: "scroll", }} className="tabs">
          {tabs.map((tab, i) => (
            <Button
              style={{
                height: "30px",
                marginTop: "5px",
                marginBottom: "-5px",
                margin: "1px",
                fontSize: "10px",
                fontWeight: "bold",
                borderRadius: "0px",
                color: "white",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
              key={i}
              id={tab.id}
              disabled={currentTab === `${tab.id}`}
              onClick={handleTabClick}
              variant="primary"
              size="sm"
            >
              {tab.tabTitle}
            </Button>
          ))}
        </div>
        <div className="content">
          {tabs.map((tab, i) => (
            <div key={i}>
              {currentTab === `${tab.id}` && (
                <div>
                  <p className="title">{tab.title}</p>
                  <div>{tab.content}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
