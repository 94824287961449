import { useState } from "react";
import { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";

import { db } from "../firebase-config.js";
import { collection, where, query, getDocs } from "firebase/firestore";

import Table from "react-bootstrap/Table";
import { recalculateStandings } from "./standings.js";

let newLeagueTeams = [];

export const StatsTeamBen = (props) => {
  const { division, location, round } = props;

  // const listTeams = collection(db, "mlpStandingsTeam");
  const [teams, setTeams] = useState([]);
  // const [mlpTeams, setmlpTeams] = useState([]);
  let groupTeams = [];
  let roundSelect = "";
  let roundSelector = "";

  const sortMatchP = (rowA, rowB) => {
    const a = Number(rowA.matchWinP.substring(0, rowA.matchWinP.length - 1));
    const b = Number(rowB.matchWinP.substring(0, rowB.matchWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortGameP = (rowA, rowB) => {
    const a = Number(rowA.gamesWinP.substring(0, rowA.gamesWinP.length - 1));
    const b = Number(rowB.gamesWinP.substring(0, rowB.gamesWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortPtsP = (rowA, rowB) => {
    const a = Number(rowA.ptsWinP.substring(0, rowA.ptsWinP.length - 1));
    const b = Number(rowB.ptsWinP.substring(0, rowB.ptsWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  if (round === "GROUP A") {
    roundSelect = "GROUP A";
  }
  if (round === "GROUP B") {
    roundSelect = "GROUP B";
  }
  if (round === "GROUP C") {
    roundSelect = "GROUP C";
  }
  if (!round.includes("GROUP")) {
    roundSelect = [
      "QUARTERFINALS 1",
      "QUARTERFINALS 2",
      "QUARTERFINALS 3",
      "QUARTERFINALS 4",
      "SIMIFINALS 1",
      "SIMIFINALS 2",
      "FINALS",
    ];
  }

  if (round === "GROUP A") {
    roundSelector = "==";
  }
  if (round === "GROUP B") {
    roundSelector = "==";
  }
  if (round === "GROUP C") {
    roundSelector = "==";
  }
  if (!round.includes("GROUP")) {
    roundSelector = "in";
  }

  useEffect(() => {
    (async () => {
      const listMatches = query(
        collection(db, "mlpStandingsTeam"),
        where("division", "==", division),
        where("event", "in", [
          "2024 MLP1",
          "2024 MLP2",
          "2024 MLP4",
          "2024 MLP5",
          "2024 MLP6",
          "2024 MLP7",
          "2024 MLP8",
          "2024 MLP9",
          "2024 MLP10",
        ])
      );

      const listTeams = query(
        collection(db, "mlpteams"),
        where("divisionID", "==", division),
        where("event", "in", [
          "2024 MLP1",
          "2024 MLP2",
          "2024 MLP3",
          "2024 MLP4",
          "2024 MLP5",
          "2024 MLP6",
          "2024 MLP7",
          "2024 MLP8",
          "2024 MLP9",
          "2024 MLP10",
        ])
      );

      const querySnapshot = await getDocs(listMatches);
      let teamsList = [];
      querySnapshot.forEach((doc) => {
        teamsList.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setTeams(teamsList);

      const queryTeamsSnapshot = await getDocs(listTeams);
      let mlpTeamsList = [];
      queryTeamsSnapshot.forEach((doc) => {
        mlpTeamsList.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      // setmlpTeams(mlpTeamsList);
      for (let b = 0; b < teamsList.length; b++) {
        for (let c = 0; c < teamsList[b].teamRank.length; c++) {
          groupTeams.push(teamsList[b].teamRank[c]);
          // console.log(groupTeams)
        }
      }

      var holder1 = {};
      var holder2 = {};
      var holder3 = {};
      var holder4 = {};
      var holder5 = {};
      var holder6 = {};
      var holder7 = {};
      var holder8 = {};
      var holder9 = {};

      groupTeams.forEach(function (d, i) {
        if (holder1.hasOwnProperty(d.team)) {
          holder1[d.team] = holder1[d.team] + d.dbloss;
        } else {
          holder1[d.team] = d.dbloss;
        }
        if (holder2.hasOwnProperty(d.team)) {
          holder2[d.team] = holder2[d.team] + d.dbwin;
        } else {
          holder2[d.team] = d.dbwin;
        }
        if (holder3.hasOwnProperty(d.team)) {
          holder3[d.team] = holder3[d.team] + d.gamesAgainst;
        } else {
          holder3[d.team] = d.gamesAgainst;
        }
        if (holder4.hasOwnProperty(d.team)) {
          holder4[d.team] = holder4[d.team] + d.gamesFor;
        } else {
          holder4[d.team] = d.gamesFor;
        }
        if (holder5.hasOwnProperty(d.team)) {
          holder5[d.team] = holder5[d.team] + d.matchesAgainst;
        } else {
          holder5[d.team] = d.matchesAgainst;
        }
        if (holder6.hasOwnProperty(d.team)) {
          holder6[d.team] = holder6[d.team] + d.matchesFor;
        } else {
          holder6[d.team] = d.matchesFor;
        }
        if (holder7.hasOwnProperty(d.team)) {
          holder7[d.team] = holder7[d.team] + d.pointsAgainst;
        } else {
          holder7[d.team] = d.pointsAgainst;
        }
        if (holder8.hasOwnProperty(d.team)) {
          holder8[d.team] = holder8[d.team] + d.pointsFor;
        } else {
          holder8[d.team] = d.pointsFor;
        }
        if (holder9.hasOwnProperty(d.team)) {
          holder9[d.team] = holder9[d.team] + d.diff;
        } else {
          holder9[d.team] = d.diff;
        }
      });

      var obj2 = [];

      for (var prop in holder1) {
        obj2.push({
          team: prop,
          dbloss: holder1[prop],
          dbwin: holder2[prop],
          diff: holder9[prop],
          gamesAgainst: holder3[prop],
          gamesFor: holder4[prop],
          matchesAgainst: holder5[prop],
          matchesFor: holder6[prop],
          pointsAgainst: holder7[prop],
          pointsFor: holder8[prop],
          regWin: holder6[prop] - holder2[prop],
          regLoss: holder5[prop] - holder1[prop],
          matches: holder6[prop] + holder5[prop],
          ptsMatch: Number(
            holder9[prop] / (holder6[prop] + holder5[prop])
          ).toFixed(2),
          matchWinP:
            Number(
              (holder6[prop] / (holder6[prop] + holder5[prop])) * 100
            ).toFixed(2) + "%",
          gamesWinP:
            Number(
              (holder4[prop] / (holder4[prop] + holder3[prop])) * 100
            ).toFixed(2) + "%",
          ptsWinP:
            Number(
              (holder8[prop] / (holder8[prop] + holder7[prop])) * 100
            ).toFixed(2) + "%",
        });
      }

      console.log(obj2);

      obj2.sort((a, b) => {
        return a.team < b.team ? -1 : 1;
      });

      obj2.sort((a, b) => {
        return a.matchesFor + a.matchesAgainst < b.matchesFor + b.matchesAgainst
          ? 1
          : -1;
      });

      obj2.sort((a, b) => {
        return a.diff < b.diff ? 1 : -1;
      });

      obj2.forEach((team, index) => {
        team.id = index+1;
      });
      setTeams(obj2);
      newLeagueTeams = obj2;
    })();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px", // override the cell padding for head cells
        fontFamily: "SofiaCondensed",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "RANK",
      selector: (row) => row.id,
      sortable: true,
      compact: true,
      width: "50px",
      style: {
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#f0612e",
        color: "white",
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: "",
      selector: (row) => row.logo,
      compact: true,
      width: "50px",
      style: { justifyContent: "center", alignContent: "center" },
    },
    {
      name: (
        <Row style={{ paddingLeft: 15 }}>
          <Row>TEAM</Row>
          <Row style={{ paddingTop: 5 }}> NAME</Row>
        </Row>
      ),
      selector: (row) => row.team,
      sortable: true,
      width: "150px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>REG</Row>
          <Row> WIN</Row>
        </Col>
      ),
      selector: (row) => row.regWin,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>REG</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.regLoss,
      sortable: true,
      width: "60px",
      compact: "true",

      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>DB</Row>
          <Row> WIN</Row>
        </Col>
      ),
      selector: (row) => row.dbwin,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>DB</Row>
          <Row>LOSS</Row>
        </Col>
      ),
      selector: (row) => row.dbloss,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: "POINTS",
      selector: (row) => row.diff,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "yellow",
      },
    },
    {
      name: "MATCHES",
      selector: (row) => row.matches,
      sortable: true,
      width: "80px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS/</Row>
          <Row> MATCH</Row>
        </Col>
      ),
      selector: (row) => row.ptsMatch,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>MATCH</Row>
          <Row>WIN %</Row>
        </Col>
      ),
      selector: (row) => row.matchWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortMatchP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> WON</Row>
        </Col>
      ),
      selector: (row) => row.gamesFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.gamesAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> WON %</Row>
        </Col>
      ),
      selector: (row) => row.gamesWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortGameP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row> WON</Row>
        </Col>
      ),
      selector: (row) => row.pointsFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.pointsAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row>WON %</Row>
        </Col>
      ),
      selector: (row) => row.ptsWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortPtsP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
  ];

  return (
    <>
      <Card
        style={{
          marginBottom: 5,
          color: "white",
          backgroundColor: "#FFFFFF33",
        }}
      >
        <Card.Header>Season Standings</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p style={{ fontSize: "14px" }}>
              Season standings reflect regular season match results:
            </p>
            <ul style={{ fontSize: "12px" }}>
              <li>3 Points: Regulation win</li>
              <li>2 Points: DreamBreaker win</li>
              <li>1 Point: DreamBreaker loss</li>
              <li>0 Points: Regulation loss</li>
            </ul>
          </blockquote>
        </Card.Body>
      </Card>
      {/* <div
        style={{
          paddingBottom: "5px",
          paddingTop: "5px",
          zoom: "100%",
          backgroundColor: "white",
        }}
        className="d-flex justify-content-around"
      >
        <Table responsive>
          <thead>
            <tr
              style={{
                fontSize: "14px",
                fontFamily: "SofiaCondensed",
              }}
            >
              <th style={{ width: "12vw" }}>Rank</th>
              <th style={{ width: "20vw" }}></th>
              <th style={{ width: "12vw" }}>
                Team
                <br></br>Name
              </th>
              <th style={{ width: "12vw" }}>REG WIN</th>
              <th style={{ width: "12vw" }}>REG LOSS</th>
              <th style={{ width: "12vw" }}>Matches Lost</th>
              <th style={{ width: "12vw" }}>DB WIN</th>
              <th style={{ width: "12vw" }}>DB LOSS</th>
              <th style={{ width: "12vw", backgroundColor: "yellow" }}>
                POINTS
              </th>
              <th style={{ width: "12vw" }}>MATCHES</th>
              <th style={{ width: "12vw" }}>PTS/MATCH</th>
              <th style={{ width: "12vw" }}>Matches Win %</th>
              <th style={{ width: "12vw" }}>Games Won</th>
              <th style={{ width: "12vw" }}>Games Lost</th>
              <th style={{ width: "12vw" }}>Games Won %</th>
              <th style={{ width: "12vw" }}>Points Won</th>
              <th style={{ width: "12vw" }}>Points Lost</th>
              <th style={{ width: "12vw" }}>Point Won %</th>
            </tr>
          </thead>
          <tbody>
            {" "}
            {teams.map((team, index) => (
              <tr style={{ fontSize: "11px" }}>
                <td
                  style={{
                    backgroundColor: "#f0612e",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {index + 1}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  id={team.logo}
                >
                  <Col xs={3}>
                    {team.logo === "maddrops" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngmaddrops.png")}
                      />
                    ) : null}{" "}
                    {team.logo === "hustlers" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pnghustlers.png")}
                      />
                    ) : null}
                    {team.logo === "pioneers" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngpioneers.png")}
                      />
                    ) : null}
                    {team.logo === "smash" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngsmash.png")}
                      />
                    ) : null}
                    {team.logo === "5s" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/png5s.png")}
                      />
                    ) : null}
                    {team.logo === "mashers" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngmashers.png")}
                      />
                    ) : null}
                    {team.logo === "blqk" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngblqk.png")}
                      />
                    ) : null}
                    {team.logo === "shock" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngshock.png")}
                      />
                    ) : null}
                    {team.logo === "eights" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngeights.png")}
                      />
                    ) : null}
                    {team.logo === "atx" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngatx.png")}
                      />
                    ) : null}
                    {team.logo === "lv" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pnglv.png")}
                      />
                    ) : null}
                    {team.logo === "clean" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngclean.png")}
                      />
                    ) : null}
                    {team.logo === "breakers" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngbreakers.png")}
                      />
                    ) : null}
                    {team.logo === "diamonds" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngdiamonds.png")}
                      />
                    ) : null}
                    {team.logo === "slice" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngslice.png")}
                      />
                    ) : null}
                    {team.logo === "bouncers" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngbouncers.png")}
                      />
                    ) : null}
                    {team.logo === "orlando" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngorlando.png")}
                      />
                    ) : null}
                    {team.logo === "aces" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngaces.png")}
                      />
                    ) : null}
                    {team.logo === "ranchers" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngranchers.png")}
                      />
                    ) : null}
                    {team.logo === "dc" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngdc.png")}
                      />
                    ) : null}
                    {team.logo === "drive" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngdrive.png")}
                      />
                    ) : null}
                    {team.logo === "dallas" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngdallas.png")}
                      />
                    ) : null}
                    {team.logo === "miami" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngmiami.png")}
                      />
                    ) : null}
                    {team.logo === "columbus" ? (
                      <Image
                        style={{ width: "30px" }}
                        src={require("./images/teams/pngcolumbus.png")}
                      />
                    ) : null}
                  </Col>
                </td>
                <td
                  style={{
                    fontFamily: "Kanit",
                    boxShadow: "3px 0 5px -2px #888",
                  }}
                >
                  <Row>
                    <Col>
                      <Row>
                        <Col
                          style={{ fontSize: "14px", width: "180px" }}
                          id={team.team}
                        >
                          {team.team}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.matchesFor - team.dbwin}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.matchesAgainst - team.dbloss}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.dbwin}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.dbloss}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                    backgroundColor: "yellow",
                  }}
                >
                  {team.diff}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.matchesFor + team.matchesAgainst}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {(
                    team.diff /
                    (team.matchesFor + team.matchesAgainst)
                  ).toFixed(2)}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {Math.floor(
                    (team.matchesFor /
                      (team.matchesAgainst + team.matchesFor)) *
                      100
                  )}
                  %
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.gamesFor}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.gamesAgainst}{" "}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {(
                    (team.gamesFor / (team.gamesAgainst + team.gamesFor)) *
                    100
                  ).toFixed(2)}
                  %
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.pointsFor}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {team.pointsAgainst}
                </td>
                <td
                  style={{
                    fontFamily: "SofiaCondensed",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {(
                    (team.pointsFor / (team.pointsAgainst + team.pointsFor)) *
                    100
                  ).toFixed(2)}
                  %
                </td>
              </tr>
            ))}{" "}
          </tbody>
        </Table>
      </div> */}
      <div
        style={{
          paddingBottom: "5px",
          paddingTop: "5px",
          zoom: "100%",
          backgroundColor: "white",
          overflowX: "scroll",
        }}
        className="d-flex justify-content-around"
      >
        <Row>
          <DataTable
            columns={columns}
            data={newLeagueTeams}
            customStyles={customStyles}
            style={{
              fontSize: "14px",
              fontFamily: "SofiaCondensed",
            }}
          />
        </Row>
      </div>
    </>
  );
};
